<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">导出日志</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="账号" class="searchboxItem ci-full">
              <span class="itemLabel">账号:</span>
              <el-input v-model="adminName" type="text" size="small" clearable placeholder="请输入账号" />
            </div>
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="fullname" type="text" size="small" clearable placeholder="请输入姓名" />
            </div>
            <div title="操作时间:" class="searchboxItem ci-full">
              <span class="itemLabel">操作时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="OperationTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
              ></el-date-picker>
            </div>
            <div class="btnBox">
              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="账号"
                align="left"
                prop="adminName"
                show-overflow-tooltip
                width="100"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="fullname"
                show-overflow-tooltip
                width="100"
              />
              <el-table-column label="操作内容" align="left" prop="exportOperContent" />
              <el-table-column
                label="操作时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                width="200"
              />
              <el-table-column
                label="操作IP"
                align="left"
                show-overflow-tooltip
                prop="exportOperIp"
                width="200"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operation/exportLog",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      adminName: "",
      fullname: "",
      OperationTime: []
    };
  },
  computed: {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.adminName) {
        params.adminName = this.adminName;
      }
      if (this.fullname) {
        params.fullname = this.fullname;
      }
      if (this.OperationTime) {
        params.startDateBegin = this.OperationTime[0];
        params.startDateEnd = this.OperationTime[1];
      }
      this.doFetch({
        url: "/run/export/queryExportList",
        params,
        pageNum
      });
    },
    // //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
